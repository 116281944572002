import { Constants } from '@adobe/aem-spa-page-model-manager';
import { useRef } from 'react';

import { ExperienceFragment } from '@/components/ExperienceFragment/ExperienceFragment';
import { Header as FeatureHeader } from '@/features/Header';

const Header = (props) => {
    const { disabled } = props;

    const { globalAlerts } = props;

    const activeAlertRef = useRef(undefined);

    const getAlertProperties = (alert) => {
        let alertItems = alert?.globalAlertFragment?.[':items']?.root?.[':items'];
        let alertProperties = Object.keys(alertItems).filter((item) =>
            alertItems[item]?.[':type'].includes('homepage-alert-banner'),
        )[0];
        return alertItems[alertProperties];
    };

    if (globalAlerts?.length && !activeAlertRef.current) {
        let filteredAlerts = globalAlerts.filter((alert) => getAlertProperties(alert)?.render);
        let alertToRender;
        if (filteredAlerts.length) {
            alertToRender = filteredAlerts[0];
            if (filteredAlerts.length >= 2) {
                let tagBasedAlert = filteredAlerts.find(
                    (alert) => getAlertProperties(alert)?.displayMode === 'tag',
                );
                if (tagBasedAlert) alertToRender = tagBasedAlert;
            }
        }
        if (alertToRender) {
            getAlertProperties(alertToRender).alertId =
                alertToRender.globalAlertFragment.localizedFragmentVariationPath;
            alertToRender.alertBannerComponent = (
                <ExperienceFragment
                    cqItems={alertToRender.globalAlertFragment[Constants.ITEMS_PROP]}
                    cqItemsOrder={alertToRender.globalAlertFragment[Constants.ITEMS_ORDER_PROP]}
                    {...alertToRender.globalAlertFragment}
                />
            );
            activeAlertRef.current = alertToRender;
        }
    }

    props.navigationItems.forEach((item) => {
        item.menuCardComponent = (
            <ExperienceFragment
                cqItems={item.menuCardExperienceFragment[Constants.ITEMS_PROP]}
                cqItemsOrder={item.menuCardExperienceFragment[Constants.ITEMS_ORDER_PROP]}
                {...item.menuCardExperienceFragment}
            />
        );
    });

    if (disabled) {
        return null;
    }

    return (
        <FeatureHeader
            {...props}
            alertBannerComponent={activeAlertRef?.current?.alertBannerComponent}
        />
    );
};

export default Header;
