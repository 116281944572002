import PropTypes from 'prop-types';

import { Button } from '@/design-system/atoms/Button';
import { Image } from '@/design-system/atoms/Image';
import { Theme } from '@/design-system/atoms/Theme';

export const headerPropTypes = {
    /**
     * The logo for the header. Links to the Homepage or another url

     */
    logo: PropTypes.shape({
        href: PropTypes.string,
        image: PropTypes.shape({
            ...Image.PropTypes,
        }),
    }),
    /**
     * Navigation items for the header
     */
    navigationItems: PropTypes.arrayOf(
        PropTypes.shape({
            parent: PropTypes.string,
            children: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string,
                    href: PropTypes.string,
                }),
            ),
            menuCardComponent: PropTypes.node,
            featureCard: PropTypes.shape({
                theme: PropTypes.oneOf(Object.values(Theme.NAME)),
                title: PropTypes.string,
                description: PropTypes.string,
                image: PropTypes.shape({
                    ...Image.PropTypes,
                }),
                cta: PropTypes.shape({ ...Button.PropTypes }),
            }),
        }),
    ),
    /**
     * The primary call to action for the header
     */
    primaryCta: PropTypes.shape({ ...Button.PropTypes }),
    /**
     * Utility navigation items for the header
     */
    utilityNavigationItems: PropTypes.arrayOf(
        PropTypes.shape({
            ...Button.PropTypes,
            isDropdown: PropTypes.bool,
            children: PropTypes.arrayOf(
                PropTypes.shape({
                    ...Button.PropTypes,
                }),
            ),
        }),
    ),
    /**
     * If the component is in AEM editor
     */
    isInEditor: PropTypes.bool,
    /**
     * Disable client side routing
     */
    disableCSR: PropTypes.bool,
    alertBannerComponent: PropTypes.node,
};
