import React from 'react';

import { useBreakpoint } from '@/hooks/useBreakpoint';

import { DesktopHeader } from './components/DesktopHeader';
import { MobileHeader } from './components/MobileHeader';
import { HeaderProvider } from './contexts/HeaderContext';
import { headerPropTypes } from './headerPropTypes';

/**
 *
 * The Header component is a wrapper for the MobileHeader and DesktopHeader components. It determines which header to render based on the current breakpoint and is a Global component.
 */
const Header = ({ coveo, searchResultsPage, ...props }) => {
    const breakpoint = useBreakpoint();
    const isMobile = !breakpoint?.largeplus;
    return (
        <HeaderProvider coveo={coveo} searchResultsPage={searchResultsPage}>
            <MobileHeader {...props} isActive={isMobile} />
            <DesktopHeader {...props} isActive={!isMobile} />
            {props.alertBannerComponent || null}
        </HeaderProvider>
    );
};

Header.propTypes = {
    ...headerPropTypes,
};

export default Header;
